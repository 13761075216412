/* PricingTable.css */
.pricing-table {
    text-align: center;
    font-family: Arial, sans-serif;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .toggle-switch {
    margin-bottom: 20px;
  }
  
  .toggle-switch input {
    display: none;
  }
  
  .toggle-switch label {
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
  }
  
  .toggle-switch span {
    color: red;
  }
  
  .plans {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .plan {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    flex: 1;
  }
  
  .plan .price {
    font-size: 24px;
    margin: 10px 0;
  }
  
  .plan button {
    background-color: #00bfa5;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .plan ul {
    list-style: none;
    padding: 0;
    text-align: left;
  }
  
  .plan li {
    margin: 10px 0;
  }
  
  .plan li svg {
    color: green;
    margin-right: 8px;
  }
  
  .plan p {
    text-align: left;
    font-size: 14px;
  }
  
  .plan p strong {
    display: block;
    margin-top: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .plan {
      width: 100%;
    }
  }