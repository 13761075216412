/* General styling */
.app-container {
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    text-align: center;
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .filter-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 30px;
  }
  
  .filter-item {
    display: flex;
    flex-direction: column;
  }
  
  .filter-item label {
    font-size: 16px;
    margin-bottom: 5px;
    color: #555;
  }
  
  .filter-item select, .filter-item input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .filter-btn {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
  }
  
  .filter-btn:hover {
    background-color: #0056b3;
  }
  
  .results-title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .results-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .results-table th, .results-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .results-table th {
    background-color: #f8f8f8;
    color: #555;
  }
  
  .results-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .results-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .pagination-btn {
    padding: 5px 15px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .pagination-btn:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  .no-results-message {
    text-align: center;
    font-size: 1.2rem;
    color: red;
    margin-top: 20px;
  }
  @media (max-width: 600px) {
    .app-container {
      width: 100%;
    }
  }
  