body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
}

.profile-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Sidebar */
.sidebar {
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.sidebar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.profile-picture {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 12px;
  border: 3px solid #68a4a5;
}

.user-name {
  font-size: 1.6em;
  font-weight: bold;
  color: #333;
  margin: 5px 0;
}

.sidebar-tabs {
  margin-top: 20px;
}

.tab-button {
  display: block;
  width: 100%;
  padding: 14px;
  margin-bottom: 10px;
  background-color: #e0e0e0;
  border: none;
  border-radius: 6px;
  text-align: left;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab-button:hover {
  background-color: #d6d6d6;
}

.tab-button.active {
  background-color: #68a4a5;
  color: white;
}

/* Main Content */
.content {
  flex-grow: 1;
  padding: 20px;
}

.tab-content {
  background-color: white;
  padding: 24px;
  border-radius: 6px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #444;
  text-align: center;
}

/* Overview Card */
.overview-card {
  background: #ffffff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.user-details p,
.plan-details p {
  margin: 12px 0;
  font-size: 18px;
  color: #555;
}

.label {
  font-weight: bold;
  color: #222;
}

/* Plan Section */
.plan-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 12px;
}

.plan-benefits {
  margin-top: 24px;
}

.benefits-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 12px;
}

.plan-benefits ul {
  list-style-type: disc;
  padding-left: 24px;
  color: #555;
  font-size: 18px;
}

.plan-benefits ul li {
  margin-bottom: 10px;
}

/* Login Section */
.not-logged-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.login-button {
  margin-top: 14px;
  padding: 12px 24px;
  background-color: #68a4a5;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-button:hover {
  background-color: #4d8687;
}

.chat-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  padding: 12px 24px;
  background-color: #68a4a5;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  z-index: 999;
}

.chat-button:hover {
  background-color: #4d8687;
  transform: translateY(-2px);
}

.chat-button.active {
  background-color: #4d8687;
  transform: scale(0.95);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.chat-popup-overlay {
  position: fixed;
  bottom: 100px;
  z-index: 998;
  animation: slideIn 0.3s ease-out;
}

.chat-left {
  left: 30px; /* Aligns to the left */
}

.chat-right {
  right: 30px; /* Aligns to the right */
}

.chat-popup-content {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 350px;
  max-height: 500px;
  overflow: hidden;
  transform-origin: bottom right;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

/* Responsive Styles */
@media (min-width: 768px) {
  .profile-page {
    flex-direction: row;
  }

  .sidebar {
    width: 280px;
  }

  .content {
    flex-grow: 1;
    padding: 30px;
  }
}

@media (max-width: 768px) {
  
  .chat-popup-overlay {
    bottom: 80px;
  }

  .chat-left {
    left: 10px;
  }

  .chat-right {
    right: 10px;
  }

  .chat-popup-content {
    width: calc(100vw - 40px);
    max-height: calc(100vh - 200px);
  }

  .chat-button {
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    font-size: 14px;
  }
  .tab-button {
    text-align: center;
  }

  .profile-picture {
    width: 90px;
    height: 90px;
  }

  .section-title {
    font-size: 1.8em;
  }

  .overview-card {
    padding: 20px;
  }

  .user-details p,
  .plan-details p {
    font-size: 16px;
  }

  .plan-benefits ul {
    font-size: 16px;
  }
}

/* Add these styles to your existing ProfilePage.css */

.edit-profile-button {
  position: relative;
  bottom: 30px;
  right: 30px;
  padding: 12px 24px;
  background-color: #68a4a5;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin-left: auto;
}

.edit-profile-button:hover {
  background-color: #4d8687;
  transform: translateY(-2px);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
}

.edit-form {
  position: relative;
}

.edit-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.input-with-edit {
  display: flex;
  gap: 8px;
}

.input-with-edit input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.input-with-edit input.editable {
  border-color: #68a4a5;
  background-color: #f8f8f8;
}

.edit-field-button {
  background: none;
  border: none;
  color: #68a4a5;
  cursor: pointer;
  padding: 4px;
}

.profile-photo-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}

.edit-profile-picture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #68a4a5;
}

.edit-photo-button {
  background: none;
  border: 1px solid #68a4a5;
  color: #68a4a5;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.form-actions {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 24px;
}

.save-button,
.cancel-button {
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.save-button {
  background-color: #68a4a5;
  color: white;
  border: none;
}

.cancel-button {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  color: #333;
}

@media (max-width: 768px) {
  .edit-profile-button {
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    font-size: 14px;
  }

  .popup-content {
    width: 95%;
    padding: 16px;
  }

  .form-actions {
    flex-direction: column;
  }

  .save-button,
  .cancel-button {
    width: 100%;
  }

  .edit-profile-picture {
    width: 100px;
    height: 100px;
  }
}